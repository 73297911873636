import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import Alert  from 'react-bootstrap/Alert';
import { LoginForm } from '../commons/LoginForm';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const VerifyAccount = () => {
    const location = useLocation();
    const parts = location.pathname.split("/");
    const token = parts[2];

    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [isVerified, setIsVerified] = useState(false); // State to track verification status
  
    useEffect(() => {
      if (token) {  // Ensure token is available before making the API call
          verifyKey(token);  // Call verifyKey only if token is valid
      }
  }, [token]);  // The effect only runs when the 'token' changes or on initial mount
  

    const verifyKey = async (token) => {
        try {
            // Call your backend API to verify the key
            const response = await axios.post(`/api/confirm_email/`,
                { key: token },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "accept": "*/*"
                    }
                }
            );

            const data = response.data;
         

            if (response.status === 200) {
                setMessage("Account successfully verified!");
                setIsVerified(true); // Set the verification status to true
            } else {
                setError("Verification failed. Please try again.");
            }
        } catch (error) {
            setError("An error occurred while verifying the account.");
            console.error("Error verifying account:", error);
        }
    };

    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            {token ? (
                isVerified ? (
                   <Container className='bg-neutral fullscreen-wrapper' fluid>
                    <Row className='w-50 m-auto'>
                      <Col>
                        {message && <Alert variant="success">{message}</Alert>}
                          <LoginForm /> 
                      </Col>
                    </Row>
                   </Container> 
                ) : (
                    <p>Verifying account...</p> // Show loading text while verifying
                )
            ) : (
                <p>Invalid or missing key.</p>
            )}
        </div>
    );
};

export default VerifyAccount;
