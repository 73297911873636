import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import axios from '../api/axios';
import {
    getExperiments,
    getAvailableExperiments,
    getSessions,
} from "../data/dataFetchers";


import { Container, ModalBody, ModalHeader, Stack } from 'react-bootstrap';
import Modal  from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert';


import { DayPicker, DateAfter, DateBefore  } from "react-day-picker";
import "react-day-picker/style.css";
import { es } from "react-day-picker/locale";





function ScheduleExperiment ({btn_text, exp_id}) {

    const axiosPrivate = useAxiosPrivate()
    const controller = new AbortController();
    const dispatch = useDispatch();
    const { auth } = useAuth();

    let id = exp_id
    var moment = require('moment'); 
    var today = moment()
    var todayF= today.toDate().toString()
    let initialDateFormated = today.format('YYYY-MM-DD')
   

    let progressBarTitles = [
        'Paso 1: Elegí la fecha del encuentro',
        'Paso 2 : Elegí el horario',
        'Paso 3 : Confirmar cita'
    ]
    
    let [showScheduleModal, setShowScheduleModal] = useState(false)
    let [now, setNow] = useState(0)
    let [progressBarSteps, setProgressBarTexts] = useState(progressBarTitles[0])
    let [hasDate, sethasDate] = useState(false)
    let [hasTime, setHasTime] = useState(false)

    let [datesunabled, setDatesUnabled]= useState()
    let [startDate, setStartDate]= useState(undefined)
    let [endDate, setEndDate]= useState(undefined)
    let [disabledWeekend, setDisabledWeekend] = useState(undefined)

    let [selectedDate, setSelectedDate] = useState()
    let [formatedDateSelected, setFormateddDateSelected] = useState(initialDateFormated)
    let [formatedForPicker, setFormateddForPicker] = useState()
    let [continueUnable, setContinueUnable]= useState(true)
   
    let [hoursAvailable, setHoursAvailable] = useState()
    let [hourSelected, setSelectedHour] = useState()

    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationVariant, setNotificationVariant] = useState('success'); // Variantes: 'success', 'danger', etc.

    const getAvailableDates= async (exp_id, controller) => {
        try {
            const response = await axiosPrivate.get(`/api/experiments/${exp_id}/booking_dates/`, {
                signal: controller.signal,
                headers:{
                     'Authorization': `Bearer ${auth.accessToken}`
                },
            });
            setStartDate(new Date ( response.data.start_date))
            setEndDate(new Date(response.data.end_date))
            setDisabledWeekend(response.data.disabled_weekend)
            setDatesUnabled(response.data.disabled_dates)
            getExperiments(controller, dispatch, axiosPrivate, auth);
            getAvailableExperiments(controller, dispatch, axiosPrivate, auth)
            getSessions(controller, dispatch, axiosPrivate, auth)
           controller.abort()
        } catch (err) {
            console.error(err);
        }
    }

    const getAvailableHours= async (exp_id, formatedDateSelected, controller) => {
        try {
            const response = await axiosPrivate.get(`/api/experiments/${exp_id}/avaliable_schedules/${formatedDateSelected}/`, {
                signal: controller.signal,
                headers:{
                     'Authorization': `Bearer ${auth.accessToken}`
                },
            });
           
           setHoursAvailable(response.data)
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        getAvailableDates(id, controller)
        return () => controller.abort();
    }, [])

   
    
    useEffect(() => {
        const controller = new AbortController();
        getAvailableHours(id, formatedDateSelected, controller)
        return () => controller.abort();
    }, [])


    const clearForm = () => {
        setNow(0);
        setHasTime(false);
        sethasDate(false);
        setProgressBarTexts(progressBarTitles[0]);
    };

    const handleClose = () => {
        setShowScheduleModal(false);
        clearForm()
    }
    const handleShow = () => {
        setShowScheduleModal(true);
        getAvailableDates(id, controller);
    }; 
    
    const handleSelectedDate = (e) => {
       let year = e.getUTCFullYear()
       let month = e.getUTCMonth() + 1
       let day = e.getUTCDate()
       let formatdDate = year + '-' + month + '-' + day
       let formatForPicker = day + '-' + month + '-' + year
       
       setSelectedDate(e)
       setFormateddDateSelected(formatdDate)
       setFormateddForPicker(formatForPicker)
       setContinueUnable(false)
    }


    const handleSelectedHour= (htime) => {
       let hour = htime
      
       setSelectedHour(hour)
       setContinueUnable(false)
     }

    const handleNextStep = () => {
        if (!hasDate) {
            getAvailableHours(exp_id, formatedDateSelected, controller);
            sethasDate(true);
            setNow(40);
            setProgressBarTexts(progressBarTitles[1]);
            setContinueUnable(true)
        } else if (hasDate && !hasTime) {
            setHasTime(true);
            setNow(80); 
            setProgressBarTexts(progressBarTitles[2]);
        } else if (hasDate && hasTime) {
            setNow(100); 
        }
    };

    const handlePreviousStep = () => {
        if (hasTime) {
            setHasTime(false);
            setNow(33); // Retrocede al 33% en el progreso
            setProgressBarTexts(progressBarTitles[1]);
        } else if (hasDate) {
            sethasDate(false);
            setNow(0); // Vuelve al 0% en el progreso
            setProgressBarTexts(progressBarTitles[0]);
        }
    };


    const handleConfirmDate = async (e) => {
        e.preventDefault();
        try {  
            const response = await axios.post(`/api/experiment_create_booking/`,
               { 
                    experiment: exp_id,
                    date: formatedDateSelected,
                    time: hourSelected
                },
                {
                    headers:{
                        'Authorization': `Bearer ${auth.accessToken}`
                   },
                    withCredentials: false
                }
            );
        
    
            // Mostrar notificación de éxito
            setNotificationMessage('Cita agendada correctamente');
            setNotificationVariant('success');
            setShowNotification(true);
            getExperiments(controller, dispatch, axiosPrivate, auth);
            getAvailableExperiments(controller, dispatch, axiosPrivate, auth);
            getSessions(controller, dispatch, axiosPrivate, auth);
            handleClose();            
            setEndDate(undefined);
            setStartDate(undefined);
            setDisabledWeekend(undefined);
            setDatesUnabled(undefined);
            clearForm();
    
            // Ocultar la notificación automáticamente después de 3 segundos
            setTimeout(() => setShowNotification(false), 3000);
        } catch (err) {
            // Mostrar notificación de error
            setNotificationMessage('Error al agendar la cita. Por favor, inténtelo de nuevo.');
            setNotificationVariant('danger');
            setShowNotification(true);
    
            // Ocultar la notificación automáticamente después de 3 segundos
            setTimeout(() => setShowNotification(false), 3000);
        }
    };
    


    const shceduleModal = <Modal show={showScheduleModal} onHide={handleClose} size='md' >
        <ModalHeader closeButton> <p className='noto-sans super-subtitles-size text-secundario fw-bolder'>Agendar Cita</p> </ModalHeader>
        <ModalBody  className='p-4'>
            <Container>
            <Row className='mb-4'>
               <p className='pt-sans fw-bolder text-center'>{progressBarSteps}</p>
            </Row>
            <Row className='mb-4'>
                <ProgressBar now={now} label={`${now}%`} />
            </Row>
            <Row className='mb-4  day-picker-row'>
            {!hasDate &&
                <DayPicker
                    locale={es}
                    mode="single"
                    selected={selectedDate}
                    onSelect={handleSelectedDate}
                    disabled= {[
                        {before: startDate, after: endDate},
                        {dayOfWeek: disabledWeekend ? [0, 6] : undefined}
                ]}   
                /> 
            }
            {hasDate & !hasTime ?
                <Stack direction="horizontal" gap={3} className='flex-wrap my-4 m-auto'>
                    {hoursAvailable.map( (h, index) => 
                        <Button 
                            key={index}
                            variant={   hourSelected === h.time ? "primary" : "outline-primary"}
                            onClick={() => handleSelectedHour(h.time)}> {h.time} </Button>
                    )} 
                </Stack> : null
            }
            { hasTime & hasDate ?
              <p className='text-center'>  El día {formatedForPicker} a las {hourSelected}</p> : null
            }
            </Row>
            <Row className='d-flex justify-content-around my-4'>
            {(hasDate || hasTime) && (
                        <Col className="text-center">
                            <Button variant="outline-secondary"  className={'day-picker-buttons'} onClick={handlePreviousStep}>
                                Volver
                            </Button>
                        </Col>
                    )}
                <Col className='text-center'>
                
                { hasDate && hasTime ? <Button onClick={handleConfirmDate} className={'day-picker-buttons'}>Agendar Cita</Button> : <Button className={'day-picker-buttons'} onClick={handleNextStep} disabled={continueUnable}>Continuar</Button> }  
                </Col>
                
            </Row>
            </Container>
        </ModalBody>
    </Modal>



return (
    <>
        <Button variant={'secondary'} className={'w-100 m-auto'} onClick={handleShow}>{btn_text}</Button>
        {shceduleModal}

        {showNotification && (
            <Alert 
                variant={notificationVariant} 
                onClose={() => setShowNotification(false)} 
                dismissible
                className="mt-3 text-center"
            >
                {notificationMessage}
            </Alert>
        )}
    </>
);
}

export default ScheduleExperiment;