import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import axios from '../api/axios';
import LoadingData from '../data/loadData';
import ChangePasswordButton from './ChangePasswordButton';

import Card from 'react-bootstrap/Card';
import Button  from "react-bootstrap/Button";
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert'

const LOGIN_URL = '/api/token/';


export function LoginForm(){

    const navigate = useNavigate();
    const location = useLocation();

    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    let navigateTo = location.pathname !== "/" ? "/complete-registration" : "/loading"


    // Estado para los modalales
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showResendMailModal, setShowResendMailModal] = useState(false);


    // Funciones para manejar el estado del modal para set password
    const handleShowPasswordModal = () => setShowPasswordModal(true);
    const handleClosePasswordModal = () => setShowPasswordModal(false);
 
    // Funciones para manejar el estado del modal para resend email
    const handleShowResendMailModal = () => setShowResendMailModal(true);
    const handleCloseResendMailModal = () => setShowResendMailModal(false);


    useEffect(() => {
        if (userRef.current) {
            userRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(LOGIN_URL, 
                JSON.stringify({ email: user, password: pwd }), {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': '*',
                },
                withCredentials: false,
            });
          
            const accessToken = response?.data?.access;
            const refreshToken = response?.data?.refresh;
            setAuth({ user, pwd, accessToken, refreshToken });
            setUser('');
            setPwd('');
            setSuccess(true);
            sessionStorage.setItem('isAuthenticated', true);
            navigate(navigateTo);
            
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
                console.log('400', errMsg)
            } else if (err.response?.status === 401) {
                const errorDetail = err.response?.data?.detail || 'Unauthorized'
                setErrMsg(errorDetail);
                if (errorDetail === "Email is not verified.") {
                    setShowResendMailModal(true);
                }
            } else {
                const errorDetail = err.response?.data?.detail || 'Login Failed'
                setErrMsg(errorDetail);
               // Verifica si el error es "Email is not verified."
                if (errorDetail === "Email is not verified.") {
                    setShowResendMailModal(true);
                }
            }
        }
    };

    const handleResendMailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/users/resend_verification_email/', 
                JSON.stringify({ email: user}), {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': '*',
                },
                withCredentials: false,
            });
            const accessToken = response?.data?.access;
            const refreshToken = response?.data?.refresh;
            setUser('');
            setPwd('');
            setSuccess(true);
            setSuccessMsg('Se ha reenviado el mail con éxito. Verificá tu bandeja de entrada (en la carpeta de spam si no lo encontrás) y seguí los pasos para validar tus datos.')
            setShowResendMailModal(false);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Mail');
                console.log('400', errMsg)
            } else if (err.response?.status === 401) {
                console.log('401', err.response?.data?.detail)
                setErrMsg(err.response?.data?.detail || 'Error al reenviar mail');
            } else {
                setErrMsg(err.response?.data?.detail || 'Error inesperado');
            }
        }
    
    };

    return(
        <Stack gap={5}>
            <Card className={'m-3 p-3 no-border'}>
                <Card.Title>
                    <p className={'noto-sans subtitles-size text-secundario fw-bold text-center mt-3'}>¡Hola de nuevo!</p>
                </Card.Title>
                <Card.Subtitle>
                    <p className={'pt-sans subtitles-size text-secundario fw-bold text-center pb-3'}>
                    Ingresá para continuar.
                    </p>
                </Card.Subtitle>
                <Card.Body>
                {errMsg && <Alert variant={'danger'}>{errMsg}</Alert>}
                {successMsg && <Alert variant={'success'}>{successMsg}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Control 
                            type="email" 
                            placeholder="Email"
                            onChange={(e) => setUser(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control 
                                type="password" 
                                placeholder="Password"
                                onChange={(e) => setPwd(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <p className={'text-center'}>
                                <span 
                                    className="text-primary" 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={handleShowPasswordModal}>
                                    ¿Olvidaste tu contraseña?
                                </span>
                            </p>
                        </Form.Group>
                        <Button className={'w-100 m-auto texts-size'} variant="primary" type='submit'>
                            Ingresar
                        </Button>
                    </Form>
                </Card.Body>
            </Card>

            {/* Modal para cambiar contraseña */}
            <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Resetear Contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChangePasswordButton />
                </Modal.Body>
            </Modal>

            {/* Modal para reenviar mail para su verificacion */}
            <Modal show={showResendMailModal} onHide={handleCloseResendMailModal}>
                <Modal.Header closeButton>
                    <Modal.Title>¡Solo falta verificar tu mail!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={handleResendMailSubmit}>
                <p>Por favor, revisa tu casilla de email, incluyendo la carpeta de spam o correo no deseado, para encontrar el email de verificación. <br></br>Si no lo has recibido, puedes reenviar el email, y te enviaremos nuevamente los pasos a seguir a tu casilla.</p>
                    <Form.Group className="mb-3 d-flex align-items-center" controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            placeholder="Ingresa tu email"
                            className="me-2"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                        />
                        <Button variant="primary" type="submit">
                            Enviar
                        </Button>
                    </Form.Group>
                </Form>

                </Modal.Body>
            </Modal>
        </Stack>
    );
}

const REGISTER_URL = 'api/users/'

export function RegisterForm(){

    const navigate = useNavigate();

    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [switchModalContent, setSwitchModalContent] = useState(false)
    const handleClose = () => {
        setShowRegisterModal(false)
        setErrMsg('')
        setUserEmail('')
        setUserPwd('')
        setMatchPwd('')
    };
    const handleShow = () => setShowRegisterModal(true);

    const [userEmail, setUserEmail] = useState('');
    const [userPwd, setUserPwd] = useState('');
    const [matchPwd, setMatchPwd] = useState('');

    const [errMsg, setErrMsg] = useState([]);
    const [success, setSuccess] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        if (userPwd !== matchPwd){
            setErrMsg(['Las contraseñas no coinciden'])
        } else {
        const email = userEmail;
        const password = userPwd;
        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ username: email, email : email, password: password}),
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Headers': '*' 
                     },
                }
            );
            setSuccess(true);
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setUserEmail('');
            setUserPwd('');
            setMatchPwd('');
            setSwitchModalContent(true)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg(err.response?.data?.password || 'Missing Username or Password');
                console.log('400', errMsg)
            } else if (err.response?.status === 401) {
                console.log('401', errMsg)
                setErrMsg(err.response?.data?.detail || 'Unauthorized');
            } else {
                setErrMsg(err.response?.data?.detail || 'Login Failed');
            }
            

        }}
    }

   

    let modalContent = 
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title >Ingresá tus datos
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className={'p-3'}>
                            <Form onSubmit={handleSubmit} className={'mx-3 p-3'}>
                            {errMsg.length > 0 && <Alert variant={'danger'}>{errMsg}</Alert>}
                                <Form.Group className="mb-3">
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Ingresá tu correo electrónico."
                                        onChange={(e) => setUserEmail(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Creá una contraseña segura."
                                        onChange={(e) => setUserPwd(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Volvé a ingresar tu contraseña."
                                        onChange={(e) => setMatchPwd(e.target.value)} />
                                </Form.Group>
                                <Button className={'w-100 m-auto my-3 texts-size'} variant="primary" type='submit' > Registrarse </Button>
                            </Form>
                        </Modal.Body>
                    </>

    let modalCheckMail = <>
                         <Modal.Header closeButton>
                            <Modal.Title > ¡Te registraste con éxito!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className={'p-3'}>
                           <p> Te mandamos un mail de confirmación. Fijate en tu bandeja de entrada (en la carpeta de spam si no lo encontrás) y seguí los pasos para validar tus datos.
                           </p>
                        </Modal.Body>
    
    </>

    return(
        <Stack gap={5}>
            <Card className={'m-3 p-3 no-border'}>
                <Card.Title> 
                    <p className={'noto-sans subtitles-size text-secundario fw-bold text-center mt-3'}>Registrate ahora y empezá a explorar
                    </p>
                </Card.Title>
                {/* <Card.Subtitle>
                    <Image src="\iso_whiteBG.svg" className={'iso-logo-card m-auto d-flex'}/>
                </Card.Subtitle> */}
                <Card.Body>
                    {/* <p className={'pt-sans text-size text-center pb-3 my-3'} >Crea tu cuenta para generar tu perfil </p> */}
                    <Button className={'w-100 mt-5 texts-size'} variant="primary" onClick={handleShow}> Registrarse</Button>
                </Card.Body>
            </Card>
            <Modal show={showRegisterModal} size={'lg'} onHide={handleClose}>
                {switchModalContent === true ? modalCheckMail :  modalContent}
            </Modal>
        </Stack>
    )
}

