import { createSlice } from "@reduxjs/toolkit";



const initialState =[]

export const subjectSlice = createSlice({
    name: 'subject',
    initialState,
    reducers:{
        addSubject(state, action){
            return action.payload;
        }
    }
})

export const selectAllSubject = (state) => state.subject;
export const { addSubject } = subjectSlice.actions;
export default subjectSlice.reducer;