import Main from "../commons/Main";
import SetPassword from "../components/setPassword";
import BackButton from "../commons/BackButton";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from "react-bootstrap/Card";
import { CardBody } from "react-bootstrap";


function ResetPassword(){

    let resetPassword =
        <Container  className="fullscreen-wrapper pt-4" fluid >
            <Row className={'mt-4 pt-4 d-flex justify-content-center align-content-center'}>
                <Card className={'w-50 p-2 text-center'}>
                    <CardBody>
                        <SetPassword/>
                    </CardBody>
                </Card>
            </Row>
        </Container>

return (
        <Main children={resetPassword} className={'bg-light'}/>
)

}

export default ResetPassword;