import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useEffect } from 'react';

import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

import Main from "../commons/Main";
import ExperimentList from '../components/experimentLists';
import Greeting from "../components/GreetingMessage";
import SessionsList from '../components/sessionsLists';

import Container from 'react-bootstrap/Container';

import {
    getSessions,
    getMessage,
    getAvailableExperiments 
} from "../data/dataFetchers";


function Home(){
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                console.log("Mount component"); 
                await Promise.all([
                    getSessions(controller, dispatch, axiosPrivate, auth),
                    getMessage(controller, dispatch, axiosPrivate, auth),
                    getAvailableExperiments(controller, dispatch, axiosPrivate, auth)
                ]);
                console.log("fetchData completed"); // Log para confirmar que todos los fetch se completaron
            } catch (error) {
                console.error("Error in fetchData:", error);
            }
        };
        fetchData()
        return () => controller.abort();
    }, [])    

    let HomeContent =
        <Container className={'mt-4 pt-4 mb-4'} fluid>
            <Greeting/>
            <SessionsList/>
            <ExperimentList/>
        </Container>


return (
        <Main children={HomeContent}/>
)

}

export default Home;