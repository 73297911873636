import TopMenu from "./TopMenu";
import Footer from "./footer"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function Main({children, className}){
    
    const isMobile = window.innerWidth < 992;

    return (
        <Container className={'content-wrapper 100vw ' + className} fluid>
            <Row>
                <Col>
                    <TopMenu/>  
                </Col> 
            </Row>
            <Row>
                <Container className={ isMobile ? "mb-0 pb-0 100vw" : "mb-4 pb-4 px-0 100vw" } fluid>
                    {children}
                </Container>
                
            </Row>
            <Row>
                <Footer/>
            </Row>
            
        </Container>
    );
} 

export default Main;