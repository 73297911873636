import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { addAvailableExperiment } from "../redux/availableExperimentsSlice";

import {
    getExperiments,
    getAvailableExperiments,
    getSessions,
    getSubject,
    getMessage,
    getMe
} from "./dataFetchers";

const LoadingData = () => {
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                console.log("fetchData started"); // Log para inicio de fetchData
                await Promise.all([
                    getExperiments(controller, dispatch, axiosPrivate, auth),
                    getAvailableExperiments(controller, dispatch, axiosPrivate, auth),
                    getSessions(controller, dispatch, axiosPrivate, auth),
                    getSubject(controller, dispatch, axiosPrivate, auth),
                    getMessage(controller, dispatch, axiosPrivate, auth),
                    getMe(controller, dispatch, axiosPrivate, auth),
                ]);
                console.log("fetchData completed"); // Log para confirmar que todos los fetch se completaron
            } catch (error) {
                console.error("Error in fetchData:", error);
            }
        };

        fetchData().then(() => navigate("/dashboard"));

        return () => controller.abort();
    }, [dispatch, axiosPrivate, auth, navigate]);

    return <div>Loading...</div>;
};

export default LoadingData;
