import { createSlice } from "@reduxjs/toolkit";



const initialState =[]

export const experimentSlice = createSlice({
    name: 'experiments',
    initialState,
    reducers:{
        addExperiment(state, action){
            console.log(" Action dispatched:", action); // Log del payload recibido
            console.log(" State before:", state); // Estado antes del cambio
            return action.payload;
        }
    }
})

export const selectAllExperiments = (state) => state.experiments;
export const { addExperiment } = experimentSlice.actions;
export default experimentSlice.reducer;