import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../api/axios';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useAuth from "../hooks/useAuth";

function SetPassword() {
    const location = useLocation();
    const parts = location.pathname.split("/");
    const uid = parts[3];
    const token = parts[4];
    const uidBase64= uid ? atob(uid) : null;

    const { auth } = useAuth();


    const [newPass, setNewPass] = useState('');
    const [matchNewPass, setMatchNewPass] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validar que las contraseñas coincidan
        if (newPass !== matchNewPass) {
            setError('Las contraseñas no coinciden.');
            return;
        }

        try {
            const response = await axios.post(
                '/dj-rest-auth/password/reset/confirm/',
                {
                    new_password1: newPass,
                    new_password2: matchNewPass,
                    uid: uid,
                    token: token
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
         
            setSuccess(true); // Mostrar alert de éxito
            setError(''); // Limpiar error
        } catch (err) {
            console.error('Error:', err.response || err);
            setError('Hubo un error al cambiar la contraseña. Intenta nuevamente.');
        }
    };

    const handleChangeSubmit = async (e) => {
        e.preventDefault();

        // Validar que las contraseñas coincidan
        if (newPass !== matchNewPass) {
            setError('Las contraseñas no coinciden.');
            return;
        }

        try {
            const response = await axios.post(
                '/dj-rest-auth/password/change/',
                {
                    new_password1: newPass,
                    new_password2: matchNewPass,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.accessToken}`
                    }
                }
            );
        
            setSuccess(true); // Mostrar alert de éxito
            setError(''); // Limpiar error
        } catch (err) {
            console.error('Error:', err.response || err);
            setError('Hubo un error al cambiar la contraseña. Intenta nuevamente.');
        }
    };

    return (
        <Container fluid>
            <p>Configura tu nueva contraseña</p>

            {success && <Alert variant="success">¡Contraseña cambiada exitosamente!</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={(e) => {
                if (location.pathname === '/administrar-cuenta') {
                    handleChangeSubmit(e);
                } else {
                    handleSubmit(e);
                }
            }}>
                <Form.Group className="mb-3" controlId="newPassword">
                    <Form.Label>Nueva contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Nueva contraseña"
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="confirmNewPassword">
                    <Form.Label>Confirmar nueva contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirmar nueva contraseña"
                        value={matchNewPass}
                        onChange={(e) => setMatchNewPass(e.target.value)}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Enviar
                </Button>
            </Form>
        </Container>
    );
}

export default SetPassword;
