import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import useAuth from "../hooks/useAuth";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import Col from 'react-bootstrap/Col'
import { Navbar, Nav, NavDropdown, Modal, Button, Offcanvas, useMediaQuery } from "react-bootstrap";
import { selectAllMe } from "../redux/meSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function TopMenu(){

    const meArray = useSelector(selectAllMe) 
    const me = meArray
    const navigate = useNavigate();
    const auth = useAuth()
    const isMobile = window.innerWidth < 992;

    const RESEARCHER_URL = process.env.REACT_APP_IS_REASERCHER_URL.toString();

    const logOut = () => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/');
      };

    const location = useLocation();

    // Estado para manejar el modal
    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    // Estado para manejar el OffCanvas
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const handleShowOffCanvas = () => setShowOffCanvas(true);
    const handleCloseOffCanvas = () => setShowOffCanvas(false);
      

      const handleRedirect = () => {
        window.location.href = RESEARCHER_URL;
    };
  
    return(
       <Stack direction="horizontal" gap={3} className={'d-flex justify-content-between p-2 mb-3 bg-secundario fixed-top vw-100'}>
        <Col className={'d-flex'}>
            <div className={isMobile ? 'logo-bg-transform-mobile bg-primario' : 'bg-primario logo-bg-transform'}>
                <Image src={"/logo_blueBG.svg"} className={'logo-position header-logo-width'}></Image>
            </div>
        </Col>
        <Col className={'d-flex justify-content-end align-items-center'}>
        {isMobile ? (
                    <>
                        <Button variant="primary" onClick={handleShowOffCanvas} className="m-3">Menú</Button>
                        <Offcanvas show={showOffCanvas} onHide={handleCloseOffCanvas} placement="end">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Menú</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="flex-column">
                                    {location.pathname !== "/" && location.pathname !== '/cambiar_contrasenia' ? (      
                                        <>
                                            {me?.is_researcher && (
                                                <Nav.Item onClick={handleRedirect}>Administrar experimentos</Nav.Item>
                                            )}
                                            <Nav.Item onClick={() => navigate('/administrar-cuenta')}>Administrar cuenta</Nav.Item>
                                            <Nav.Item onClick={logOut}>Cerrar sesión</Nav.Item>
                                        </>                                      
                                    ) : (
                                        <Nav.Link onClick={handleOpenModal} className="noto-sans super-subtitles-size">
                                            ¿Quiénes somos?
                                        </Nav.Link>
                                    )}
                                </Nav>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </>
                ) : (
                    <Nav className="d-flex">
                        {location.pathname !== "/" && location.pathname !== '/cambiar_contrasenia' ? (
                            <NavDropdown className='noto-sans super-subtitles-size' title={<FontAwesomeIcon icon={faUser} />}>
                                {me?.is_researcher && (
                                    <NavDropdown.Item onClick={handleRedirect}>Administrar experimentos</NavDropdown.Item>
                                )}
                                <NavDropdown.Item onClick={() => navigate('/administrar-cuenta')}>Administrar cuenta</NavDropdown.Item>
                                <NavDropdown.Item onClick={logOut}>Cerrar sesión</NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            <Nav.Link onClick={handleOpenModal} className="noto-sans super-subtitles-size text-white">
                                ¿Quiénes somos?
                            </Nav.Link>
                        )}
                    </Nav>
                )} 
        </Col>

        {/* Modal */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Laboratorio de Inteligencia Artificial Aplicada
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>El Laboratorio de Inteligencia Artificial Aplicada (LIAA) pertenece al Departamento de Computación, Facultad de Ciencias Exactas y Naturales, Universidad de Buenos Aires y al Instituto de Investigación en Ciencias de la Computación, CONICET- UBA.</p>
                
            <p>El LIAA es un laboratorio interdisciplinario, que combina proyectos de la Lingüística Computacional, Neurociencia Computacional, Procesamiento de Lenguaje Natural, Sistemas de Diálogo, Reconocimiento del Habla y Análisis en Tiempo Real de Señales del Cerebro. Para poder llevar adelante la mayoría de nuestros proyectos de investigación requerimos de datos. Para poder conseguir esos datos, necesitamos que muchas personas resuelvan distintas tareas mientras los grabamos o anotamos cierta información. ¡Ayudanos a conseguir esto! </p>
               
            <p>Si sos investigador y querés sumar tu experimento a nuestro sitio comunicate con nosotros a través del siguiete mail: 
            <a href="mailto:somosdatapruebas@gmail.com" className="no-decoration"> somosdatapruebas@gmail.com</a>
            </p>
          </Modal.Body>
        </Modal>
       </Stack>
    );
}

export default TopMenu;
