import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image  from 'react-bootstrap/Image';
import TopMenu from '../commons/TopMenu';
import Footer from '../commons/footer';
import {LoginForm, RegisterForm } from '../commons/LoginForm';
import Main from '../commons/Main';

function Login() {

  const navigate = useNavigate();
  const isMobile = window.innerWidth < 992;


  useEffect(() => {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated') === 'true';
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [navigate]);


  const loginContent = 
          <Container  fluid>
              <Row className={'mb-3 pb-3 pl-3 mr-0 vw-100' } >
                <div className={ isMobile ? ' d-none' : 'm-0 p-0 w-75'}>
                  <Image src={'/landing_image.svg'} className={'bg-float'} ></Image>
                </div>
              </Row >
                <Col md={8} className={' pt-3 '}>
                  <p className={'nunito-sans super-titles-size p-3 m-3'}>¡Ayudanos a hacer ciencia!</p>
                  {/*<p className={'crimson super-subtitles-size p-3 mx-3 w-75'}>Breve descripcion de los objetivos  o para que sirven los datos recolectadosen relacion a la ciencia.</p>*/}
                  <p className={ (isMobile ? 'w-100' : 'w-90') + ' noto-sans subtitles-size text-secundario fw-bold p-3 m-3'}>Ayudanos a investigar cómo funciona la mente a través de divertidos y sencillos experimentos online y presenciales. <br></br> Tus datos nos ayudarán a hacer avances científicos.
                  </p>
                </Col>
              <Row className='bg-neutral mr-0'>
                <Col md={5} lg={4} className={' bg-neutral p-4'}>
                  <LoginForm/>
                </Col>
                <Col md={5} lg={4} className={'  bg-neutral p-4'}>
                  <RegisterForm/>
                </Col>
              </Row>
              <div className={'bg-img bg-float'}> </div>
            </Container>


  return (
    <Main  children={loginContent}/> 
  );
}

export default Login;
