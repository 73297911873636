import React from "react";
import { Button, Container, Card } from "react-bootstrap";
import SubjectForm from "../commons/subjectForm";
import BackButton from "../commons/BackButton"
import SetPassword from "./setPassword";

const Admin = ({ onPasswordReset }) => {
  return (
    <Container className="m-4">
      <BackButton
        text="Volver"
        link= "/dashboard"
      />
        <p className={'noto-sans super-subtitles-size text-secundario fw-bolder p-3'}> Tus Datos </p>
          <div className="px-2">
          <SubjectForm buttonText={'Guardar'} />
            
            </div>
          {/* Botón para resetear la contraseña */}
          <div className="mt-3">
          <p className={'noto-sans super-subtitles-size text-secundario fw-bolder p-3 '}> Modificá tu contraseña </p>
            <SetPassword/>
          </div>
    </Container>
  );
};

export default Admin;
