import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col'
import { Stack } from "react-bootstrap";
import {faXTwitter} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Footer(){

    const isMobile = window.innerWidth < 992;

    return(
       <Container className={ isMobile ? 'bg-secundario p-3' : 'bg-secundario p-3 fixed-bottom' } fluid>
            <Row className={'mx-0'}>
                <Stack direction={isMobile ? "vertical" : "horizontal"} className="justify-content-between align-items-center" gap={isMobile ? 3 :2}>
                    {/* Logo on the left */}
                        <a href={'https://liaa.dc.uba.ar/'}
                        ><Image
                            src={"/liaa-logo.png"}
                            className={'footer-image'}    
                        />
                    </a>
                    {/* Centered Text */}
                    <p className="m-0 text-center fw-bold text-primario">
                        Cualquier duda podés comunicarte por mail a: <br />
                        <a href="mailto:somosdatapruebas@gmail.com" className="no-decoration text-white">somosdatapruebas@gmail.com</a>
                    </p>

                    {/* X (Twitter) Icon on the right */}
                    <a href="https://x.com/liaa_icc" target="_blank" rel="noopener noreferrer" className="no-decoration" >
                        <FontAwesomeIcon icon={faXTwitter} size="2x" color="#f8f9fa" />
                    </a>
                </Stack>
            </Row>
        </Container> 
    );
}

export default Footer;