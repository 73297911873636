// sessionsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const sessionsSlice = createSlice({
    name: "sessions",
    initialState,
    reducers: {
        setSessions(state, action) {
            return action.payload; // Reemplaza el estado actual con una nueva lista de sesiones
        },
        addSessions(state, action) {
            return action.payload; 
        },
    },
});

export const selectAllSessions = (state) => state.sessions;
export const { setSessions, addSessions } = sessionsSlice.actions;
export default sessionsSlice.reducer;
