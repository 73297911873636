import { createSlice,  } from "@reduxjs/toolkit";

const initialState = null

export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        addMessage(state, action) {
            return action.payload;
        },
    },
});

export const selectAllMessage = (state) => state.message;
export const { addMessage } = messageSlice.actions;
export default messageSlice.reducer;
