
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {selectAllMessage } from "../redux/messageSlice";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const Greeting = () => {
    const messages = useSelector(selectAllMessage);
   

    return (
        <Container fluid>
            <Row>
                <Col>
                <div dangerouslySetInnerHTML={{ __html: messages.content }}></div>
                </Col>
            </Row>
        </Container>

    );
};

export default Greeting;
